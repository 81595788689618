import type { ISimpleRegion } from '~/types'

export default class RegionsApi {
  private readonly axios = axiosClient
  name: string

  constructor(name: string) {
    this.name = name
  }

  async get(): Promise<ISimpleRegion> {
    try {
      const headers: { [key in string]: string } = {}
      const rawHeaders = useRequestHeaders()

      headers['x-forwarded-for-nuxt'] = rawHeaders['x-forwarded-for-nuxt']

      return await this.axios.$get('/region_name/', {
        params: { translit: this.name },
        headers,
      })
    } catch {
      return await this.axios.$get('/region_name/', { params: { translit: this.name } })
    }
  }
}
