import { AWARD_ROUTE_NAMES } from '~/features/Home/constants'
import { RegionApi } from '~/features/city/api'

// NOTE: async middleware не работают в prod сборке если определять их в компоненте
export default defineNuxtRouteMiddleware(async (to) => {
  const mainStore = useMainStore()
  const { city, year } = to.params as { city: string, year: string }

  // Проверяю, есть ли инфа о текущем регионе в сторе
  // Если нет -> Загружаю и перезаписываю в стор
  // Если есть -> ничего не делаю
  // Если транслит города -> редирект на страницу регионов
  if (mainStore.region?.translit !== city) {
    try {
      const api = new RegionApi(city)
      const region = await api.get()
      mainStore.region = { ...region, translit: city } // Добавляю translit для того, чтобы идентифицировать текущий регион
    } catch (e) {
      return navigateTo({ name: AWARD_ROUTE_NAMES.main, params: { year } })
    }
  }
})
